import { useMemo } from 'react';

import { useTerminal, useUserLocation } from '@/hooks';

export const useOnSiteInfo = () => {
  const { userLocation } = useUserLocation();
  const { terminal } = useTerminal();

  const hasPairedTerminal = useMemo(() => !!(userLocation && terminal), [terminal, userLocation]);

  const authenticationPassPhrase = useMemo(
    () => `${userLocation?.locationUuid}-${terminal?.authorization?.keyId}:${terminal?.authorization?.key}`,
    [terminal, userLocation]
  );

  return {
    authenticationPassPhrase,
    hasPairedTerminal,
  };
};
