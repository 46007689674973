import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getCoordinates, locationType } from '@/api/gmap';
import { gmapKeys } from '@/api/gmap/gmap.helpers';

export const useCoordinatesQueryOptions = {
  queryKey: (requestParams?: { address: string }) => [...gmapKeys.all, 'getCoordinates', requestParams] as const,
};

export interface CoordinatesQueryProps<TData> {
  requestParams: { address: string };
  options?: Omit<
    UseQueryOptions<locationType, Error, TData, ReturnType<typeof useCoordinatesQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
}

export const useCoordinatesQuery = <TData = locationType>({ requestParams, options }: CoordinatesQueryProps<TData>) => {
  return useQuery({
    queryFn: () => getCoordinates(requestParams),
    queryKey: useCoordinatesQueryOptions.queryKey(requestParams),
    ...options,
  });
};
