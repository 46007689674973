import { Button, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';

import PageLoader from '@/components/PageLoader';

export const ProtectedRoute = () => {
  const auth = useAuth();

  switch (auth.activeNavigator) {
    case 'signinSilent':
    case 'signoutRedirect': {
      if (!auth.isAuthenticated) {
        return <PageLoader />;
      }
    }
  }

  if (auth.isLoading && !auth.isAuthenticated) {
    return <PageLoader />;
  }

  const signInUser = async () =>
    await auth.signinRedirect({
      redirect_uri: `${window.origin}${window.location.pathname}`,
    });

  if (auth.error) {
    return (
      <Stack gap={4} alignItems="center" height="100%" justifyContent="center">
        <Text>Oops... {auth.error.message}</Text>
        <Button variant="outline" onClick={signInUser}>
          <Trans>Sign in</Trans>
        </Button>
      </Stack>
    );
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect({
      redirect_uri: `${window.origin}${window.location.pathname}`,
    });
  }

  if (auth.isAuthenticated) {
    window.history.replaceState({}, document.title, window.location.pathname);
    return <Outlet />;
  }

  return null;
};
