import { Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { FaClock, FaMapMarkerAlt } from 'react-icons/fa';

import Card from '@/components/Card';
import OrderPickupMap from '@/components/Order/OrderPickupMap';
import { getLocalCalendarFormat } from '@/dayjs';

export interface OrderInfoCardProps {
  kitchenAccessInstructions: string;
  kitchenAddress: string;
  kitchenName: string;
  pickupTime?: Date | null;
}

export const OrderInfoCard = ({
  kitchenName,
  kitchenAddress,
  kitchenAccessInstructions,
  pickupTime,
}: OrderInfoCardProps) => {
  const formattedPickupTime = pickupTime ? getLocalCalendarFormat(dayjs(pickupTime)) : null;

  const orderInfos = [
    {
      icon: FaMapMarkerAlt,
      title: kitchenName,
      Subtitle: (
        <Stack fontWeight={400} color="gray.disabled" maxW="90%">
          <Text>{kitchenAddress}</Text>
          <Text>{kitchenAccessInstructions}</Text>
        </Stack>
      ),
    },
    {
      icon: FaClock,
      title: formattedPickupTime ?? <Trans>ASAP (10min)</Trans>,
    },
  ];

  return (
    <Card>
      <Flex flexDirection="column" gap={6}>
        <Text fontWeight={700} fontSize={18}>
          <Trans>Order details</Trans>
        </Text>
        <OrderPickupMap kitchenAddress={kitchenAddress} />
        {orderInfos.map(({ icon, title, Subtitle }, index) => {
          return (
            <Flex gap={4} key={index} alignItems="start">
              <Icon as={icon} w={6} h={6} color="gray.icons" />
              <Flex flexDirection="column" flexWrap="nowrap" w="100%">
                <Text fontWeight={500} noOfLines={2} maxW="90%">
                  {title}
                </Text>
                {Subtitle}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Card>
  );
};
