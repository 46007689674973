import { useLocalStorage } from '@uidotdev/usehooks';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useMemo } from 'react';

type PendingOrderContextValue = {
  pendingOrder: string | null;
  setPendingOrder: Dispatch<SetStateAction<string | null>>;
};

const PendingOrderContext = createContext<PendingOrderContextValue | null>(null);

const usePendingOrder = () => {
  const context = useContext(PendingOrderContext);

  if (!context) throw new Error('Make sure you are using usePendingOrder within a PendingOrderProvider');

  return context;
};

const PendingOrderProvider = ({ children }: PropsWithChildren) => {
  const [pendingOrder, setPendingOrder] = useLocalStorage<string | null>('pendingOrder', null);

  const value = useMemo(
    () => ({
      pendingOrder,
      setPendingOrder,
    }),
    [pendingOrder, setPendingOrder]
  );

  return <PendingOrderContext.Provider value={value}>{children}</PendingOrderContext.Provider>;
};

export { PendingOrderProvider, usePendingOrder };
