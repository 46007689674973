import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';
import { useAuthToken } from '@/api/useAuthToken';

import { InitializationBundle, PairTerminalRequest } from './types';

export const usePairTerminalMutation = (
  options?: Omit<UseMutationOptions<InitializationBundle, ApiError, PairTerminalRequest>, 'mutationFn'>
) => {
  const {
    gatewayClickCollect: { terminalsApi },
  } = useTasterApis();

  const { getToken } = useAuthToken();

  return useMutation({
    ...options,
    mutationFn: async (mutationParams) => {
      const token = await getToken();

      return terminalsApi.pairTerminal(mutationParams, async ({ init }) => ({
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Bearer ${token}`,
        },
      }));
    },
  });
};
