import { CouponInfo, CouponInfoErrorTypeEnum } from '@/api/customer-order/orders/types';

export interface Modifier {
  available: boolean;
  label: string;
  menuItemUuid: string;
  modifierUuid: string;
  sellingPrice: number;
  modifierGroups: ModifierGroup[];
  nutritionalInfo: NutritionalInfo;
}

export interface ModifierGroup {
  available: boolean;
  description: string;
  included: number;
  label: string;
  max: number;
  modifierGroupUuid: string;
  modifiers: Modifier[];
}

interface NutritionalInfo {
  allergens: Array<string>;
  diets: Array<string>;
  energyKcal: number | null;
  hfss: boolean;
}

export interface MenuElement {
  available: boolean;
  description: string;
  imageUrl: string;
  label: string;
  menuElementUuid: string;
  menuItemUuid: string;
  modifierGroups: ModifierGroup[];
  sellingPrice: number;
  nutritionalInfo: NutritionalInfo;
}

export interface Category {
  description: string;
  label: string;
  menuElementUuids: string[];
  uuid: string;
}

export interface Menu {
  conceptLabel: string;
  kitchenLabel: string;
  categories: Category[];
  conceptLogo: string;
  currencyCode: string;
  description: string;
  imageUrl: string;
  menuElements: MenuElement[];
  isOpen: boolean;
  shifts: Shift[];
  locationUuid: string;
  label: string;
  uuid: string;
  restaurantName: string;
  restaurantAddress: string;
  accessInstructions: string;
  opensAt: string;
  urlGoogleReviews: string;
  openingHours: Record<string, Shift[]>;
  conceptUuid: string;
}

export type Shift = {
  shiftStart?: string;
  shiftEnd: string;
};

export interface OrderItem {
  uuid: string;
  quantity: number;
  label: string;
  modifiers: OrderItem[];
}

export interface Order {
  uuid: string;
  coupon_info?: {
    coupon_accepted: boolean;
    error_type?: CouponInfoErrorTypeEnum;
    coupon_code: string;
    coupon_reduction: number;
    price_before_reduction: number;
    reducted_price: number;
  };
  location_uuid: string;
  restaurant_platform_id: string;
  status: string;
  number: string;
  total_price: number;
  currency: string;
  kitchen_lang: string;
  items: OrderItem[];
  placed_at: string;
  ready_at: string;
  pickup_time: string;
  concept: string;
  coupon_code?: string;
}

export interface PrepareOrderResponse {
  order: Order;
  pi_secret: string;
  payment_uuid: string;
  stripe_client_id?: string;
}
export interface PrepareOrderOnSite {
  order: Order;
  pi_secret: string;
  payment_uuid: string;
  stripe_client_id?: string;
  stripe_ready_total?: number;
  stripe_account_country?: string;
}

export interface CartModifierRequest {
  menu_item_uuid: string;
  quantity: number;
  // price: number;
  modifier_uuid: string;
  modifier_groups: CartModifierGroupRequest[];
}
export interface CartModifierGroupRequest {
  menu_item_uuid: string;
  modifier_group_uuid: string;
  modifiers: CartModifierRequest[];
}

export interface CartItemRequest {
  menu_item_uuid: string;
  quantity: number;
  customer_instructions: string;
  menu_element_uuid: string;
  modifier_groups: CartModifierGroupRequest[];
}

export interface CartRequest {
  restaurant_platform_id: string;
  items: CartItemRequest[];
  cutlery: boolean;
  customer_instructions: string;
  pickup_time?: string;
  coupon_code?: string;
}

export interface PairTerminalRequest {
  locationUuid: string;
  terminalPairingCode: string;
  previousTerminalUuid?: string;
}

export interface SendCustomerEmailRequest {
  paymentUuid: string;
  customerEmail: string;
  orderUuid: string;
  marketingConsent?: boolean;
}

export interface DeletePaymentOrderMutation {
  locationUuid: string;
  terminalUuid: string;
}

export interface PrepareOrderOnSiteRequest {
  restaurantPlatformId: string;
  items: CartItemRequest[];
  cutlery?: boolean;
  customerInstructions?: string;
  deliveryBy?: string;
  pickuptime?: string;
  orderUuid?: string;
  terminalUuid?: string;
  onsite?: boolean;
  customerName?: string;
}

export interface TerminalAuthInfo {
  terminalInfo: TerminalInfo;
  authorization: {
    keyId: string;
    key: string;
  };
}

export interface TerminalInfo {
  locationUuid: string;
  terminalUuid: string;
}

export enum PairingTerminalErrorEnum {
  invalidPairingCode = 'invalid_pairing_code',
  locationInactivated = 'location_inactivated',
  addressUnavailable = 'address_cannot_parse',
}

export interface GetTerminalStatusMutation {
  locationUuid: string;
  terminalUuid: string;
}

export enum TerminalStatusEnum {
  READY = 'READY',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface TerminalStatus {
  locationUuid?: string;
  terminalUuid?: string;
  status: TerminalStatusEnum;
  paymentUuid?: string;
}

export enum OrderStatusEnum {
  ACCEPTED = 'accepted',
  CREATED = 'created',
  CREATION_FAILED = 'creation_failed',
  DELIVERED = 'delivered',
  INITIATED = 'initiated',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_SUCCEEDED = 'payment_succeeded',
  PROCESSABLE = 'processable',
  PROCESSING_PAYMENT = 'processing_payment',
  READY_FOR_DISPATCH = 'ready_for_dispatch',
}

export type CouponError = {
  errorType: CouponInfoErrorTypeEnum;
  missingAmount?: number;
};

export interface ValidOrder {
  restaurantPlatformId: string;
  couponInfo?: CouponInfo & { error: CouponError };
  locationUuid: string;
  status: OrderStatusEnum;
  totalPrice: number;
  currency: string;
  concept: string;
  items: any;
}
