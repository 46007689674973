import { Box, Container, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { ConceptInformation } from '@/api/gateway-click-collect';
import type { Coords } from '@/api/gmap';
import { CitiesBanner } from '@/routes/BrandPage/CitiesBanner';

interface BrandWrapperProps {
  concept: ConceptInformation;
  coords?: Coords;
  children: ReactNode;
}

export const BrandWrapper = ({ coords, concept, children }: BrandWrapperProps) => {
  const mainBackgroundColor = concept.primaryColorHex || '#F2F2F2';
  const mainColor = concept.primaryColorHex ? 'white' : 'black';
  const largestHeroPicture = concept.heroPictures.reduce((max, current) =>
    current.width > max.width ? current : max
  ).url;

  return (
    <Box backgroundColor="black" display="flex" flexDirection="column" height={coords ? '100%' : 'auto'} minH="100%">
      <Box display="grid" width="100%" gridTemplateRows="min-content 280px" position="absolute">
        <CitiesBanner cities={concept.cities} />
        <Container px={0} display="flex" flexDirection="column" height="100%" position="relative">
          {largestHeroPicture && <Image src={largestHeroPicture} height={280} objectFit="cover" width="100%" />}
        </Container>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" height="auto" minH="100%" width="100%" position="relative">
        <Container
          px={2}
          py={5}
          overflow={coords ? 'hidden' : 'initial'}
          display="grid"
          gridTemplateRows={!coords ? '240px auto' : '0px 1fr'}
          style={{ transition: 'all 0.8s ease' }}
          zIndex={2}
          flex={1}
        >
          <Box />
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            maxHeight={coords ? '100%' : 'min-content'}
            overflow={coords ? 'scroll' : 'initial'}
            borderRadius={8}
            backgroundColor={mainBackgroundColor}
            color={mainColor}
            boxShadow="0px 0px 20px rgba(0,0, 0, .3)"
            padding={4}
            gap={4}
            zIndex={2}
          >
            {children}
          </Box>
        </Container>
        <CitiesBanner cities={concept.cities} position={coords ? 'absolute' : 'unset'} bottom={0} />
      </Box>
    </Box>
  );
};
