import { Button, Stack, useBoolean, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { MdNoFood } from 'react-icons/md';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import {
  ConceptInformation,
  Restaurant,
  useConceptsConfigurationQuery,
  useRestaurantMenuQuery,
  useRestaurantOutOfStocksQuery,
  useRestaurantQuery,
} from '@/api/gateway-click-collect';
import { MenuElement } from '@/api/types';
import PageLoader from '@/components/PageLoader';
import { RestaurantInfo } from '@/components/RestaurantInfo';
import WebviewModal from '@/components/WebviewModal';
import { MINUTE_MS } from '@/constants';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { canPreOrder, isRestaurantOpen } from '@/helpers/openingHours.helpers';
import { getAllergensLink } from '@/helpers/restaurant.helpers';
import { getWebViewType } from '@/helpers/webview.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useUpdateMetadata } from '@/hooks/useUpdateMetadata';
import { AppLayout } from '@/layouts/AppLayout';
import { CategoriesList } from '@/routes/MenuPage/CategoriesList';
import { ItemDrawer } from '@/routes/MenuPage/ItemDrawer';
import { formatMenu } from '@/routes/MenuPage/MenuPage.helpers';
import { Menu } from '@/routes/MenuPage/types';
import type { ItemCart } from '@/types';

import { CartDrawer } from './CartDrawer';
import { MenuHero } from './MenuHero';

interface MenuProps {
  conceptInformation: ConceptInformation;
  menu: Menu;
  restaurant: Restaurant;
}

const MenuBody = ({ conceptInformation, menu, restaurant }: MenuProps) => {
  const {
    address,
    currencyCode,
    kitchenInstructions,
    kitchenLabel,
    locationUuid,
    openingHours,
    restaurantLabel,
    restaurantPlatformId,
    status,
    timezone,
  } = restaurant;
  const { label, description } = menu;
  const isPreOrderAvailable = canPreOrder({ openingHours, timezone, status });
  const isOrderAvailable = isRestaurantOpen(status) || isPreOrderAvailable;
  const allergensLink = getAllergensLink(restaurant.languageCode);

  usePageViewTracker(restaurant.kitchenLabel, restaurant.restaurantLabel);
  useUpdateMetadata({ title: label, description });

  const { cart, setCart } = useCart();
  const { isOpen: isWebviewModalOpen, onOpen: onWebviewModalOpen, onClose: onWebviewModalClose } = useDisclosure();
  const [isWebviewModalDone, setIsWebviewModalDone] = useBoolean(false);

  const [selectedItem, setSelectedItem] = useState<MenuElement | null>(null);
  const isItemDrawerOpen = !!selectedItem;

  const onUpdateCart = (item: ItemCart) => {
    if (!isWebviewModalDone && getWebViewType() === 'webview_ios') {
      onWebviewModalOpen();
      setIsWebviewModalDone.on();
    }

    const items = cart.restaurantPlatformId === restaurantPlatformId ? [...cart.items, item] : [item];
    setCart({
      accessInstructions: kitchenInstructions,
      conceptLabel: restaurantLabel,
      items,
      kitchenLabel,
      restaurantAddress: address,
      restaurantName: restaurantLabel,
      restaurantPlatformId,
    });
    setSelectedItem(null);

    dataLayer.logItemAdded(kitchenLabel, restaurantLabel, item, 'online');
  };

  const [searchParams] = useSearchParams();
  const redirectionURI = searchParams.get('redirectionURI');

  return (
    <AppLayout hasArrowBack showWebviewWarning title={restaurantLabel} url={redirectionURI ?? `/${locationUuid}`}>
      <MenuHero
        canPreOrder={isPreOrderAvailable}
        heroPictures={conceptInformation.heroPictures}
        isRestaurantOpen={isRestaurantOpen(status)}
        openingHours={openingHours}
        timezone={timezone}
      />
      <Stack p={6} pb={16} mx="auto" gap={6}>
        <Stack gap={3}>
          <RestaurantInfo
            restaurant={restaurant}
            menuDescription={menu.description}
            sx={{ justifyContent: 'stretch' }}
          />
          {allergensLink && (
            <Button
              as="a"
              target="_blank"
              variant="link"
              leftIcon={<MdNoFood />}
              href={allergensLink}
              sx={{ justifyContent: 'stretch' }}
            >
              <Trans>See allergens</Trans>
            </Button>
          )}
        </Stack>
        <CategoriesList currencyCode={currencyCode} menu={menu} onItemClick={setSelectedItem} />
      </Stack>
      <ItemDrawer
        currencyCode={currencyCode}
        isOpen={isItemDrawerOpen}
        isOrderAvailable={isOrderAvailable}
        item={selectedItem}
        onAddToCart={onUpdateCart}
        onClose={() => {
          setSelectedItem(null);
        }}
      />
      {isOrderAvailable && <CartDrawer restaurant={restaurant} />}
      <WebviewModal isOpen={isWebviewModalOpen} onClose={onWebviewModalClose} />
    </AppLayout>
  );
};

export const MenuPage = () => {
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const { cart, resetCart } = useCart();

  useEffect(() => {
    if (cart.restaurantPlatformId !== restaurantPlatformId) {
      resetCart();
    }
  }, [cart, resetCart, restaurantPlatformId]);

  const { data: conceptInformations, isLoading: isLoadingConcepts } = useConceptsConfigurationQuery({
    options: {
      cacheTime: Infinity,
      select: (conceptConfigs) => conceptConfigs.concepts,
      staleTime: Infinity,
    },
  });

  const {
    data: restaurant,
    isLoading: isLoadingRestaurant,
    isSuccess: isSuccessRestaurant,
  } = useRestaurantQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: MINUTE_MS * 5,
    },
    requestParams: {
      restaurantPlatformId,
      withOpeningInformation: true,
    },
  });

  const {
    data: menu,
    isLoading: isLoadingMenu,
    isSuccess: isSuccessMenu,
  } = useRestaurantMenuQuery({
    options: { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5 },
    requestParams: { restaurantPlatformId },
  });

  const {
    data: outOfStocks,
    isLoading: isLoadingOutOfStocks,
    isSuccess: isSuccessOutOfStocks,
  } = useRestaurantOutOfStocksQuery({
    options: { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5, select: (response) => new Set(response) },
    requestParams: { restaurantPlatformId },
  });

  const isLoading = isLoadingRestaurant || isLoadingOutOfStocks || isLoadingMenu || isLoadingConcepts;
  const conceptInformation = conceptInformations?.find(({ uuid }) => uuid === restaurant?.conceptUuid);

  if (isSuccessRestaurant && isSuccessMenu && isSuccessOutOfStocks && conceptInformation) {
    const formattedMenu = formatMenu(menu, outOfStocks);

    return <MenuBody conceptInformation={conceptInformation} menu={formattedMenu} restaurant={restaurant} />;
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return <Navigate to="/notFound" />;
};
