import { Flex, Image, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import dayjs from 'dayjs';

import { OrderStatusEnum } from '@/api/gateway-click-collect/orders';
import bagsAnimation from '@/assets/anim/lottie_bags.json';
import burgerAnimation from '@/assets/anim/lottie_burger.json';
import orderDeliveredImgSrc from '@/assets/img/order_delivered.png';
import orderRejectedImgSrc from '@/assets/img/order_rejected.png';
import { LottieAnimation } from '@/components/LottieAnimation';

export interface OrderStatusCardProps {
  number: string;
  status: OrderStatusEnum;
  readyAt?: Date | null;
}

const getOrderDetails = ({ number, status }: Pick<OrderStatusCardProps, 'number' | 'status'>) => {
  switch (status) {
    case OrderStatusEnum.CreationInProgress:
    case OrderStatusEnum.PaymentSucceeded:
    case OrderStatusEnum.Created:
    case OrderStatusEnum.Accepted: {
      return {
        graphicContent: <LottieAnimation animation={burgerAnimation} sx={{ height: '110' }} />,
        text: (
          <Trans>
            Thank you! Your order{' '}
            <Text as="span" fontWeight={700}>
              #{number}
            </Text>{' '}
            is being prepared
          </Trans>
        ),
        textColor: 'black',
      };
    }
    case OrderStatusEnum.ReadyForDispatch: {
      return {
        graphicContent: <LottieAnimation animation={bagsAnimation} sx={{ height: '110', width: '95px' }} />,
        text: (
          <Trans>
            Lets go! Your order <Text fontWeight={700}>#{number}</Text> is ready!
          </Trans>
        ),
        textColor: 'green.success.main',
      };
    }
    case OrderStatusEnum.Delivered: {
      return {
        graphicContent: <Image src={orderDeliveredImgSrc} />,
        text: t`Thanks ! Enjoy your meal ;)`,
        textColor: 'green.success.main',
      };
    }
    default: {
      return {
        graphicContent: <Image src={orderRejectedImgSrc} />,
        text: t`We encountered a problem`,
      };
    }
  }
};

const OrderStatusCard = ({ number, status, readyAt }: OrderStatusCardProps) => {
  const orderDetails = getOrderDetails({ number, status });
  const showStatus = readyAt ? dayjs(readyAt).diff(dayjs(), 'minute') <= 10 : false;

  if (showStatus && orderDetails) {
    return (
      <Flex flexDirection="column" alignItems="center" gap={4}>
        {orderDetails.graphicContent}
        <Text fontSize={18} color={orderDetails.textColor} textAlign="center">
          {orderDetails.text}
        </Text>
      </Flex>
    );
  }

  return null;
};

export default OrderStatusCard;
