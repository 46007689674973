import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';

import { PrepareTerminalOrderRequest, ReadiedOrderSummary, ResponseError } from './types';

export const usePrepareWebOrderMutation = <TContext = unknown>(
  options?: Omit<
    UseMutationOptions<ReadiedOrderSummary, ResponseError, PrepareTerminalOrderRequest, TContext>,
    'mutationFn'
  >
) => {
  const {
    gatewayClickCollect: { ordersApi },
  } = useTasterApis();

  return useMutation({
    ...options,
    mutationFn: (requestParams) => {
      return ordersApi.prepareWebOrder(requestParams);
    },
  });
};
