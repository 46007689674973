import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { ConceptInformation } from '@/api/gateway-click-collect/configurations';
import { RestaurantDeliveryPlatformInformation } from '@/api/gateway-click-collect/locations';
import { Restaurant, useRestaurantQueryOptions } from '@/api/gateway-click-collect/restaurants';
import { RestaurantCard } from '@/components/RestaurantCard';
import { getSortedRestaurantsWithConcept } from '@/helpers/brands.helpers';
import { RestaurantWithConcept } from '@/types';

interface RestaurantsListProps {
  restaurants: Restaurant[];
  isDelivery?: boolean;
  locationUuid: string;
  deliveryPlatformsMap?: { [key: string]: Array<RestaurantDeliveryPlatformInformation> | undefined };
  conceptsInformations: ConceptInformation[];
}

export const RestaurantsList = ({
  restaurants,
  conceptsInformations,
  deliveryPlatformsMap,
  isDelivery,
}: RestaurantsListProps) => {
  const conceptListStyleProperties = {
    cardMaxWidth: useBreakpointValue<'100%' | '400px'>({ base: '100%', lg: '400px' }),
    cardMinWidth: useBreakpointValue<'100%' | '350px'>({ base: '100%', lg: '350px' }),
    flexDirection: useBreakpointValue<'column' | 'row'>({ base: 'column', lg: 'row' }),
    flexWrap: useBreakpointValue<'wrap' | 'nowrap'>({ base: 'nowrap', lg: 'wrap' }),
  };

  const restaurantWithConcepts: RestaurantWithConcept[] = restaurants
    .map(({ conceptUuid, ...restaurant }) => ({
      ...restaurant,
      concept: conceptsInformations.find(({ uuid }) => uuid === conceptUuid),
    }))
    .filter((restaurantWithConcept): restaurantWithConcept is RestaurantWithConcept => !!restaurantWithConcept.concept);

  const sortedConcepts = getSortedRestaurantsWithConcept(restaurantWithConcepts);
  const queryClient = useQueryClient();

  const seedRestaurantQueryCache = (restaurantWithConcept: RestaurantWithConcept) => {
    const { concept: _, ...restaurant } = restaurantWithConcept;

    return queryClient.setQueryData<Restaurant>(
      useRestaurantQueryOptions.queryKey({
        restaurantPlatformId: restaurant.restaurantPlatformId,
      }),
      () => restaurant
    );
  };

  return (
    <Flex
      w="100%"
      gap={6}
      direction={conceptListStyleProperties.flexDirection}
      flexWrap={conceptListStyleProperties.flexWrap}
      justifyContent="center"
      alignItems="center"
      px={6}
      pt={8}
    >
      {sortedConcepts.map((restaurantWithConcept) => (
        <Box
          maxW={conceptListStyleProperties.cardMaxWidth}
          minW={conceptListStyleProperties.cardMinWidth}
          key={restaurantWithConcept.restaurantPlatformId}
          alignItems="stretch"
        >
          <RestaurantCard
            onRestaurantClick={seedRestaurantQueryCache}
            isDelivery={isDelivery}
            restaurantWithConcept={restaurantWithConcept}
            restaurantPlatforms={deliveryPlatformsMap?.[restaurantWithConcept.concept.uuid]}
          />
        </Box>
      ))}
    </Flex>
  );
};
