import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import type { Coords } from '@/api/gmap';
import { getAddress } from '@/api/gmap';

export const useAddressMutation = (options?: Omit<UseMutationOptions<string, Error, Coords>, 'mutationFn'>) => {
  return useMutation({
    mutationFn: (requestParams) => getAddress(requestParams),
    ...options,
  });
};
