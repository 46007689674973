import { Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Marker, StaticGoogleMap } from 'react-static-google-map';

import { useCoordinatesQuery } from '@/api/gmap';

const MAPS_API_KEY = import.meta.env.VITE_MAPS_API_KEY;

interface Props {
  kitchenAddress: string;
}

const OrderPickupMap = ({ kitchenAddress }: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapWidth, setMapWidth] = useState(0);

  const {
    data: coordinates,
    isLoading: isLoadingCoordinates,
    isError: isCoordinatesError,
  } = useCoordinatesQuery({ requestParams: { address: kitchenAddress } });

  useEffect(() => {
    if (!mapRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setMapWidth(mapRef.current?.clientWidth ?? 0);
    });

    resizeObserver.observe(mapRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleClick = () => {
    if (coordinates && typeof coordinates === 'object' && 'lat' in coordinates) {
      open(`https://www.google.com/maps/search/?api=1&loading=async&query=${coordinates.lat},${coordinates.lng}`);
    }
  };

  if (isCoordinatesError) return null;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={6}
      direction="column"
      ref={mapRef}
      _hover={coordinates ? { cursor: 'pointer' } : {}}
    >
      {isLoadingCoordinates && <Spinner size="xl" my={8} />}
      {coordinates && (
        <StaticGoogleMap
          size={`${mapWidth}x250`}
          apiKey={MAPS_API_KEY}
          maptype="terrain"
          zoom="16"
          onClick={() => handleClick()}
        >
          <Marker location={coordinates} iconURL={`${location.origin}/gmaps_marker_icon.png`} />
        </StaticGoogleMap>
      )}
    </Flex>
  );
};

export default OrderPickupMap;
