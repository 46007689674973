import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { getGeocode } from 'use-places-autocomplete';

import type { GeocodeResult } from '@/api/gmap';

export const useGeocodeMutation = (
  options?: Omit<UseMutationOptions<GeocodeResult, Error, Record<string, string>>, 'mutationFn'>
) => {
  return useMutation({
    mutationFn: (requestParams) => getGeocode(requestParams),
    ...options,
  });
};
