import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { Navigate } from 'react-router-dom';

import { useTerminalQuery } from '@/api/gateway-click-collect/terminals/useTerminalQuery';
import { AsyncContent } from '@/components/AsyncContent';
import { useTerminal, useUserLocation } from '@/hooks';
import { PairDeviceLayout } from '@/routes/PayOnSite/PairDeviceLayout';
import { DeviceInfo } from '@/routes/TerminalSettingsPage/DeviceInfo';
import { UserInfo } from '@/routes/TerminalSettingsPage/UserInfos';

import { TerminalStatusCard } from './TerminalStatusCard';
import { TerminalStatusErrorCard } from './TerminalStatusCard/TerminalStatusErrorCard';

export const TerminalSettingsPage = () => {
  const { userLocation } = useUserLocation();
  const { terminal } = useTerminal();

  const {
    data: registeredTerminal,
    isLoading,
    refetch,
    error,
    dataUpdatedAt,
    errorUpdatedAt,
    isError,
    isRefetching,
  } = useTerminalQuery({
    options: {
      enabled: !!terminal,
      initialDataUpdatedAt() {
        return dayjs().valueOf();
      },
    },
    requestParams: terminal
      ? {
          locationUuid: terminal.terminalInfo.locationUuid,
          terminalUuid: terminal.terminalInfo.terminalUuid,
        }
      : undefined,
  });

  if (!userLocation || !terminal) {
    return <Navigate to="/onSite/internalLocations" />;
  }

  const { kitchenLabel, locationUuid } = userLocation;

  return (
    <PairDeviceLayout
      headerTitle={t`Settings`}
      hasBackButton
      url={`/onSite/${locationUuid}`}
      backButtonText={t`Back to menu`}
    >
      <Stack gap={{ base: 2, sm: 6 }} maxW={700} justifyContent="center" mx="auto" px={2}>
        <UserInfo kitchenLabel={kitchenLabel} />
        <Text fontSize={20} fontWeight={700}>
          <Trans>Device Information</Trans>
        </Text>
        <AsyncContent
          data={registeredTerminal}
          isLoading={isLoading}
          isError={isError}
          hasData={!!registeredTerminal}
          LoadingContent={
            <Stack gap={2}>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Stack>
          }
          ErrorContent={
            <Stack gap={6}>
              <TerminalStatusErrorCard
                onRefreshClick={refetch}
                lastUpdateAt={errorUpdatedAt}
                isRefreshing={isRefetching}
                errorStatus={error?.status}
              />
              <DeviceInfo cardMachineId={terminal.terminalInfo.terminalUuid} deviceId={terminal.authorization.keyId} />
            </Stack>
          }
          renderContent={({ locationUuid, terminalUuid, status }) => (
            <Stack gap={6}>
              <TerminalStatusCard
                onRefreshClick={refetch}
                lastUpdateAt={dataUpdatedAt}
                isRefreshing={isRefetching}
                locationUuid={locationUuid}
                registeredTerminalStatus={status}
                terminalUuid={terminalUuid}
              />
              <DeviceInfo cardMachineId={terminalUuid} deviceId={terminal.authorization.keyId} />
            </Stack>
          )}
        />
      </Stack>
    </PairDeviceLayout>
  );
};
