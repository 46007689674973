import { Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';
import { PairTerminalButton } from '@/components/PairingTerminal/PairTerminalButton';

export interface DeviceInfoProps {
  deviceId: string;
  cardMachineId: string;
}

export const DeviceInfo = ({ deviceId, cardMachineId }: DeviceInfoProps) => {
  return (
    <Card padding={6}>
      <Stack width="100%" gap={6}>
        <Stack justifyContent="space-between">
          <Text fontSize={20} fontWeight={700}>
            <Trans>Device ID</Trans>
          </Text>
          <Text fontSize={20}>{deviceId}</Text>
        </Stack>
        <Divider />
        <HStack justifyContent="space-between">
          <Stack>
            <Text fontSize={20} fontWeight={700}>
              <Trans>Card machine ID</Trans>
            </Text>
            <Text textAlign="end" fontSize={20}>
              {cardMachineId}
            </Text>
          </Stack>
          <PairTerminalButton />
        </HStack>
      </Stack>
    </Card>
  );
};
