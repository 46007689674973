import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { BsLink } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export const PairTerminalButton = ({ sx, variant = 'outline', ...buttonProps }: ButtonProps) => {
  return (
    <Button
      as={Link}
      backgroundColor="white"
      colorScheme="gray"
      leftIcon={<Icon as={BsLink} sx={{ fontSize: 20 }} />}
      sx={sx}
      to="/onSite/internalLocations"
      variant={variant}
      {...buttonProps}
    >
      <Trans>Pair card machine</Trans>
    </Button>
  );
};
