import { FormControl, useToast, VStack } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { ChakraStylesConfig, InputActionMeta, Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useEffect, useId, useState } from 'react';
import usePlacesAutocomplete, { getLatLng, Suggestion } from 'use-places-autocomplete';

import type { Coords } from '@/api/gmap';
import { useAddressMutation } from '@/api/gmap/useAddressMutation';
import { useGeocodeMutation } from '@/api/gmap/useGeocodeMutation';
import { UserLocationButton } from '@/components/UserLocationButton';
import { logLocationClick } from '@/helpers/dataLayer.helpers';

export type Geolocation = {
  address?: string;
  coords?: Coords;
};

type GeolocationAutocompleteProps = {
  defaultValue?: string;
  onSelect: (value: Geolocation | null) => void;
  location: Geolocation | null;
};

export const GeolocationAutocomplete = ({ defaultValue = '', location, onSelect }: GeolocationAutocompleteProps) => {
  const id = useId();
  const inputId = `${id}-input`;
  const labelId = `${id}-label`;
  const toast = useToast();
  const [isAddressLoading, setAddressLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Suggestion>();

  const {
    setValue,
    suggestions: { loading: isSuggestionsLoading, data: suggestions },
    value,
  } = usePlacesAutocomplete({
    defaultValue,
    requestOptions: {
      language: dayjs.locale(),
    },
    debounce: 600,
  });

  const handleInputChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setValue(inputValue);
    }
  };

  useEffect(() => {
    if (!!location && !selectedOption) {
      setSelectedOption({ ...location, description: location?.address ?? '' });
    } else if (!location && !!selectedOption) {
      setSelectedOption(null);
    }
  }, [location, onSelect, selectedOption, setValue]);

  const { mutate: getGeocode } = useGeocodeMutation();

  const handleChange = (option: Suggestion | null) => {
    setSelectedOption(option);
    if (option) {
      const address = option.description;
      getGeocode(
        { address },
        {
          onSuccess: (results) => {
            if (results?.[0]) {
              const { lat, lng } = getLatLng(results?.[0]);
              onSelect({ address, coords: { latitude: lat, longitude: lng } });
            }
          },
        }
      );
    } else {
      onSelect(option);
      setValue('');
    }
  };

  const { mutate: getAddress } = useAddressMutation();

  const handleUserPosition = ({ coords }: GeolocationPosition) => {
    const { latitude, longitude } = coords;

    getAddress(
      { latitude, longitude },
      {
        onSuccess: (address) => {
          onSelect({ coords: { latitude, longitude }, address });
          logLocationClick();
        },
        onError: () => {
          toast({
            description: t`No results found. Please try again with a different location.`,
            duration: 5000,
            id: `${id}-error-toast`,
            isClosable: true,
            status: 'error',
            position: 'top',
          });
        },
        onSettled: () => setAddressLoading(false),
      }
    );
  };

  const chakraSelectStyles: ChakraStylesConfig = {
    control: (base) => ({
      ...base,
      background: '#F8F7F5',
      borderRadius: '28px',
      color: 'black',
      height: '56px',
    }),
    menuList: (base) => ({
      ...base,
      color: 'black',
      background: '#F8F7F5',
    }),
    option: (base) => ({
      ...base,
      background: '#F8F7F5',
      '&:hover': {
        background: 'gray.100',
      },
    }),
    dropdownIndicator: () => ({ display: 'none' }),
  };

  return (
    <VStack gap={4}>
      <FormControl>
        <Select
          aria-labelledby={labelId}
          defaultValue={selectedOption}
          defaultInputValue={value}
          filterOption={null}
          getOptionLabel={(option) => option.description}
          getOptionValue={(option) => option.place_id}
          id={inputId}
          noOptionsMessage={() => null}
          onChange={handleChange}
          onInputChange={handleInputChange}
          openMenuOnClick={!!value}
          options={suggestions}
          placeholder={<Trans>Address, city or postal code</Trans>}
          inputValue={value}
          value={selectedOption}
          closeMenuOnSelect
          isClearable
          isDisabled={isAddressLoading}
          isLoading={isSuggestionsLoading}
          chakraStyles={chakraSelectStyles}
        />
      </FormControl>
      {!location?.coords && (
        <UserLocationButton
          onWaitingUserPermission={() => setAddressLoading(true)}
          isLoading={isAddressLoading}
          borderRadius={28}
          onGeolocation={handleUserPosition}
          width="100%"
          backgroundColor="black"
          color="white"
          _hover={{ bg: '#18181b' }}
          border={1}
          height={14}
          borderColor="#383838"
        />
      )}
    </VStack>
  );
};
