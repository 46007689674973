import { InMemoryWebStorage, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import dataLayer from '@/helpers/dataLayer.helpers';

export const authProviderOptions: AuthProviderProps = {
  client_id: import.meta.env.VITE_APP_IAM_CLIENT_ID,
  authority: import.meta.env.VITE_APP_IAM_AUTH_SERVER_URL,
  monitorSession: true,
  post_logout_redirect_uri: window.location.origin,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    const isPwaMode = window.matchMedia('(display-mode: standalone)').matches;
    dataLayer.logLoginEvent(isPwaMode ? 'PWA' : 'BROWSER');
  },
  automaticSilentRenew: false,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
};
