import { Center, Container, Image, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Navigate, useParams } from 'react-router-dom';

import { useConceptsConfigurationQuery } from '@/api/gateway-click-collect';
import TasterStretchGif from '@/assets/img/taster-stretch.gif';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { BrandWrapper } from '@/routes/BrandPage/BrandWrapper';
import { RestaurantList } from '@/routes/BrandPage/RestaurantList';

import { Geolocation, GeolocationAutocomplete } from './GeolocationAutocomplete';

export const BrandPage = () => {
  usePageViewTracker();

  const { i18n } = useLingui();
  const [userLocation, setUserLocation] = useSessionStorage<Geolocation | null>('location', null);
  const { conceptUuid } = useParams() as { conceptUuid: string };

  const { data: concept, isLoading: isConceptLoading } = useConceptsConfigurationQuery({
    requestParams: { conceptUuid },
    options: {
      staleTime: Infinity,
      select: ({ concepts }) => {
        return concepts[0];
      },
    },
  });

  if (concept) {
    const tagline = concept.tagLines[i18n.locale] ?? concept.tagLines[0];
    const largestLogo = concept.logoPictures.reduce((max, current) => (current.width > max.width ? current : max)).url;

    return (
      <BrandWrapper coords={userLocation?.coords} concept={concept}>
        <VStack align="stretch" gap={6}>
          {!!userLocation?.coords && largestLogo && (
            <Image src={largestLogo} alt="logo" height={16} objectFit="contain" />
          )}
          <Text
            paddingTop={userLocation?.coords ? 0 : 4}
            fontSize={userLocation?.coords ? 'xl' : '3xl'}
            fontWeight={700}
            textAlign="center"
            textTransform="uppercase"
          >
            <Trans>Choose a store</Trans>
          </Text>
          <GeolocationAutocomplete
            defaultValue={userLocation?.address}
            location={userLocation}
            onSelect={setUserLocation}
          />
        </VStack>

        {userLocation?.coords ? (
          <RestaurantList
            coords={userLocation.coords}
            conceptUuid={conceptUuid}
            backgroundColor={concept.primaryColorHex}
            onErrorButtonClick={() => setUserLocation(null)}
          />
        ) : (
          <Text fontSize="4xl" fontWeight="900" textTransform="uppercase" py={3} overflowWrap="anywhere">
            {tagline}
          </Text>
        )}
      </BrandWrapper>
    );
  } else if (isConceptLoading) {
    return (
      <Container height="100%">
        <Center height="100%">
          <Image width="30rem" height="30rem" src={TasterStretchGif} />
        </Center>
      </Container>
    );
  }

  return <Navigate to="/notFound" />;
};
