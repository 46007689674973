import { Box, BoxProps, keyframes, Text } from '@chakra-ui/react';
import { debounce } from 'lodash-es';
import { useLayoutEffect, useReducer, useRef, useState } from 'react';

const MIN_MULTIPLIER = 2;

const citiesBanner = keyframes`  
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`;

export interface CitiesBannerProps extends BoxProps {
  cities?: string[];
}

export const CitiesBanner = ({ cities = [], ...props }: CitiesBannerProps) => {
  const [animationKey, restartAnimation] = useReducer((i) => i + 1, 0);
  const animation = `${citiesBanner} 30s linear infinite`;

  const boxRef = useRef<HTMLInputElement>();

  const [multiplier, setMultiplier] = useState(MIN_MULTIPLIER);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (boxRef.current) {
        const screenWidth = boxRef.current.getBoundingClientRect().width * 2;
        const textWidth = boxRef.current.children?.[0].getBoundingClientRect().width;
        const newMultiplier = Math.ceil(screenWidth / textWidth);

        setMultiplier(newMultiplier > 1 ? newMultiplier : MIN_MULTIPLIER);
        restartAnimation();
      }
    };

    handleResize();
    window.addEventListener('resize', debounce(handleResize, 50));

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!cities.length) return null;

  return (
    <Box
      key={animationKey}
      ref={boxRef}
      whiteSpace="nowrap"
      width="100%"
      overflow="hidden"
      height={10}
      alignContent="center"
      color="white"
      background="black"
      {...props}
    >
      {Array.from({ length: multiplier }).map((_, index) => (
        <Text
          key={index}
          animation={animation}
          fontSize="xl"
          whiteSpace="pre"
          fontWeight={800}
          display="inline-block"
          textTransform="uppercase"
        >
          {cities.join(' • ') + ' • '}
        </Text>
      ))}
    </Box>
  );
};
