import { Button, HStack, Icon, keyframes, SystemStyleObject, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';

import { MINUTE_MS } from '@/constants';

const rotateKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    } 
`;

export interface RefreshTerminalStatusContentProps {
  lastUpdatedAt: number;
  isRefreshing: boolean;
  onRefreshClick: () => void;
  sx?: SystemStyleObject;
}

export const RefreshTerminalStatusContent = ({
  isRefreshing,
  lastUpdatedAt,
  onRefreshClick,
  sx,
}: RefreshTerminalStatusContentProps) => {
  const [lastUpdateRelativeTime, setLastUpdateRelativeTime] = useState(() => dayjs().to(dayjs(lastUpdatedAt)));

  useEffect(() => {
    setLastUpdateRelativeTime(dayjs().to(dayjs(lastUpdatedAt)));

    const interval = setInterval(() => {
      setLastUpdateRelativeTime(dayjs().to(dayjs(lastUpdatedAt)));
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [lastUpdatedAt]);

  return (
    <HStack sx={{ ...sx }}>
      <Button
        leftIcon={
          <Icon as={FiRefreshCcw} animation={isRefreshing ? `${rotateKeyframes} 2s linear infinite` : undefined} />
        }
        onClick={onRefreshClick}
        variant="text"
        width="fit-content"
        colorScheme="gray"
        fontWeight={600}
        px={0}
      >
        <Trans>Refresh</Trans>
      </Button>
      <Text fontWeight={300}>
        <Trans>Updated {lastUpdateRelativeTime}</Trans>
      </Text>
    </HStack>
  );
};
