import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { GetRestaurantStatusRequest, RestaurantStatus } from './types';

export const useRestaurantStatusQueryOptions = {
  queryKey: (requestParams: GetRestaurantStatusRequest) =>
    [...restaurantsKeys.all, 'getRestaurantStatus', requestParams] as const,
};

export interface RestaurantStatusQueryProps<TData> {
  requestParams: GetRestaurantStatusRequest;
  options?: Omit<
    UseQueryOptions<RestaurantStatus, Error, TData, ReturnType<typeof useRestaurantStatusQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
}

export const useRestaurantStatusQuery = <TData = RestaurantStatus>({
  requestParams,
  options,
}: RestaurantStatusQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    queryKey: useRestaurantStatusQueryOptions.queryKey(requestParams),
    queryFn: () => restaurantsApi.getRestaurantStatus(requestParams),
    ...options,
  });
};
