import type { Coords, GeocodeResult, locationType } from '@/api/gmap';

export const getGeocode = async (params: Record<string, string>): GeocodeResult => {
  const query = new URLSearchParams({
    key: import.meta.env.VITE_MAPS_GEOCODING_API_KEY,
    ...params,
  });

  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?${query}`);
  const jsonResponse = await response.json();

  if (jsonResponse.status !== 'OK') {
    throw new Error(`Response status: ${jsonResponse.error_message || 'Unknown error'}`);
  }

  return jsonResponse;
};

export const getCoordinates = async ({ address }: { address: string }): Promise<locationType> => {
  const response = await getGeocode({ address });

  if (response.status !== 'OK') {
    throw new Error(`Response status: ${response.error_message}`);
  }

  return response.results[0].geometry.location;
};

export const getAddress = async ({ latitude, longitude }: Coords): Promise<string> => {
  const response = await getGeocode({ latlng: `${latitude},${longitude}` });

  if (response.status !== 'OK') {
    throw new Error(`Response status: ${response.error_message}`);
  }

  return response.results?.[0]?.formatted_address;
};
