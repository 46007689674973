import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormControl, Heading, Image, Input, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

import { usePairTerminalMutation } from '@/api/gateway-click-collect/terminals';
import { PairingTerminalErrorEnum } from '@/api/types';
import PairingIndications from '@/assets/img/pairing_tutorial.gif';
import Terminal from '@/assets/img/terminal.png';
import Card from '@/components/Card';
import { PairingTerminalFailureContent } from '@/components/PairingTerminal/PairingTerminalFailureContent';
import { PairingTerminalLoadingContent } from '@/components/PairingTerminal/PairingTerminalLoadingContent';
import { PairingTerminalSuccessContent } from '@/components/PairingTerminal/PairingTerminalSuccessContent';
import { useTerminal, useUserLocation } from '@/hooks';

import { PairDeviceLayout } from './PairDeviceLayout';

const PairTerminalIndications = () => {
  return (
    <Flex position="relative" width={500} maxWidth="calc(100% - 16px)">
      <Image src={PairingIndications} alt="pairing-indications" maxWidth={260} maxHeight={425} objectFit="contain" />
      <Flex position="absolute" top={100} left={150}>
        <Image src={Terminal} alt={t`payment terminal`} width={260} height={420} objectFit="contain" />
        <Card
          sx={{ padding: '12px' }}
          width={200}
          position="absolute"
          left={150}
          top={200}
          height={200}
          display={{ base: 'none', sm: 'block' }}
        >
          <Stack gap={2}>
            <Box background="gray.300" height="15px" width={50} />
            <Heading fontWeight={500} fontSize={14}>
              <Trans>Register this terminal</Trans>
            </Heading>
            <Box background="gray.300" height="15px" />
            <Box background="gray.300" height="15px" width={120} />
          </Stack>
          <Flex
            marginTop={4}
            border={'1px solid gray.300'}
            justifyContent="space-between"
            borderRadius={8}
            gap={2}
            padding={2}
            alignItems="center"
          >
            <Box background="gray.300" height="15px" width={50} />-{' '}
            <Box background="gray.300" height="15px" width={50} />-
            <Box background="gray.300" height="15px" width={50} />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

const PairTerminalProcess = () => {
  const { userLocation } = useUserLocation();
  const { terminal, setTerminal } = useTerminal();
  const [pairingCode, setPairingCode] = useState(['', '', '']);

  const {
    error,
    isError,
    isLoading,
    isSuccess,
    mutate: pairTerminal,
  } = usePairTerminalMutation({
    onSuccess: (initializationBundle) => {
      setTerminal(initializationBundle);
    },
  });

  const isPairingCodeInvalid = error?.reason === PairingTerminalErrorEnum.invalidPairingCode;

  const handlePairingCodeChange = (value: string, index: number) => {
    setPairingCode((oldPairingCode) => {
      const previous = [...oldPairingCode];
      previous[index] = value.trim().toLowerCase();
      return previous;
    });
  };

  if (!userLocation) {
    return <Navigate to="/onSite/internalLocations" />;
  }

  const handleSubmit = () => {
    pairTerminal({
      terminalPairingRequest: {
        locationUuid: userLocation.locationUuid,
        previousTerminalUuid: terminal?.terminalInfo.terminalUuid,
        terminalPairingCode: pairingCode.join('-'),
      },
    });
  };

  if (isSuccess) {
    return <PairingTerminalSuccessContent locationUuid={userLocation.locationUuid} />;
  }

  if (isLoading) {
    return <PairingTerminalLoadingContent />;
  }

  if (isError && !isPairingCodeInvalid) {
    return <PairingTerminalFailureContent handleRetryClick={handleSubmit} errorStatus={error.reason} />;
  }

  return (
    <Stack justifyContent="start" alignItems="center" gap="100px" padding={2}>
      <PairTerminalIndications />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Stack gap={54} alignItems="center">
          <Heading fontWeight={700} size="lg">
            <Trans>Enter code</Trans>
          </Heading>
          <Flex gap={2} flexWrap="nowrap" alignItems="center" justifyContent="center">
            {pairingCode.map((inputValue, index) => {
              return (
                <Flex
                  alignItems="center"
                  gap={2}
                  key={index}
                  sx={{ '&:not(:last-child)::after': { content: '"-"', fontSize: 'md', fontWeight: '700' } }}
                >
                  <FormControl isRequired>
                    <Input
                      value={inputValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePairingCodeChange(e.target.value, index)
                      }
                      borderColor={isPairingCodeInvalid ? 'red.400' : 'gray.300'}
                      background="white"
                      maxWidth="200px"
                      padding={4}
                      border="1px solid"
                    />
                  </FormControl>
                </Flex>
              );
            })}
          </Flex>
          {isPairingCodeInvalid && (
            <Flex
              borderRadius={12}
              padding={8}
              background="red.error.25"
              gap={3}
              border="1px solid"
              borderColor="red.error.300"
            >
              <InfoOutlineIcon color="red.600" />
              <Stack>
                <Text fontSize="sm" fontWeight={500} color="red.700">
                  <Trans>Wrong code</Trans>
                </Text>
                <Text fontSize="sm" fontWeight={400} color="red.700">
                  <Trans>Please enter the code displayed on the card machine</Trans>
                </Text>
              </Stack>
            </Flex>
          )}
          <Button type="submit" width="100%" maxWidth={220} paddingY={8} fontSize={24}>
            <Trans>Pair card machine</Trans>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export const PairTerminalPage = () => {
  const auth = useAuth();

  const handleLogout = () =>
    auth.signoutRedirect({ post_logout_redirect_uri: `${window.origin}${window.location.pathname}` });

  return (
    <PairDeviceLayout onLogoutUser={handleLogout} headerTitle={t`Pair a card machine`}>
      <PairTerminalProcess />
    </PairDeviceLayout>
  );
};
