import { Badge, Button, Card, Flex, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { TiLocationArrowOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import { Restaurant } from '@/api/gateway-click-collect/restaurants';
import { useRestaurantOpeningHoursQuery } from '@/api/gateway-click-collect/restaurants/useRestaurantOpeningHoursQuery';
import { useRestaurantStatusQuery } from '@/api/gateway-click-collect/restaurants/useRestaurantStatusQuery';
import type { Coords } from '@/api/gmap';
import { logDirectionsClick } from '@/helpers/dataLayer.helpers';
import { formatDistance } from '@/helpers/intl.helpers';
import { OpeningHoursLabel } from '@/routes/BrandPage/OpeningHoursLabel';
import { OrderButton } from '@/routes/BrandPage/OrderButton';

export interface RestaurantCardProps {
  coords: Coords;
  restaurant: Restaurant;
  backgroundColor?: string;
  color?: string;
}

export const RestaurantCard = ({
  restaurant,
  coords,
  backgroundColor = 'black',
  color = 'white',
}: RestaurantCardProps) => {
  const navigate = useNavigate();
  const getGoogleMapUrl = (address: string) => {
    const { latitude, longitude } = coords;

    return encodeURI(`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${address}`);
  };
  const navigateToMenu = () =>
    navigate(`/menu/${restaurant.restaurantPlatformId}?redirectionURI=/brand/${restaurant.conceptUuid}`);

  const {
    data: restaurantStatus,
    isLoading: isLoadingRestaurantStatus,
    isError: isRestaurantStatusError,
  } = useRestaurantStatusQuery({
    requestParams: {
      restaurantPlatformId: restaurant.restaurantPlatformId,
    },
  });

  const {
    data: restaurantOpeningHours,
    isLoading: isLoadingRestaurantOpeningHours,
    isError: isRestaurantOpeningHoursError,
  } = useRestaurantOpeningHoursQuery({
    requestParams: {
      restaurantPlatformId: restaurant.restaurantPlatformId,
    },
  });

  return (
    <Card width="100%" borderRadius={20} p={4} bg="#F8F7F5" color="#303030" flexDirection="column" gap={4}>
      <VStack align="stretch">
        <Flex justify="space-between">
          <Text fontSize="large" fontWeight={800}>
            {restaurant.restaurantLabel}
          </Text>
          <Badge
            boxShadow="0px 0px 0px 1px #D9D9D9"
            borderRadius={16}
            fontSize="xs"
            fontWeight={800}
            paddingBlock={1}
            paddingX={2}
            variant="outline"
            height="fit-content"
            textTransform="lowercase"
          >
            {formatDistance(restaurant.distance ?? 0)}
          </Badge>
        </Flex>
        <Text fontSize="sm">{restaurant.address}</Text>
        {isRestaurantOpeningHoursError || isRestaurantStatusError ? (
          <Text fontSize="xs" fontWeight={600} color="#FE001A">
            <Trans>
              Click & Collect is currently unavailable. However, you can still place your order in person at the store.
            </Trans>
          </Text>
        ) : (
          <OpeningHoursLabel
            isLoading={isLoadingRestaurantOpeningHours || isLoadingRestaurantStatus}
            status={restaurantStatus?.status}
            timezone={restaurant.timezone}
            openingHours={restaurantOpeningHours}
          />
        )}
      </VStack>

      <Flex gap="4" justify="space-between">
        <Button
          as="a"
          colorScheme="gray"
          href={getGoogleMapUrl(restaurant.address)}
          leftIcon={<TiLocationArrowOutline size={20} />}
          target="_blank"
          py={2}
          px={4}
          borderRadius={72}
          variant="ghost"
          onClick={() => logDirectionsClick(restaurant.kitchenLabel)}
        >
          <Trans>Directions</Trans>
        </Button>
        <OrderButton
          status={restaurantStatus?.status}
          timezone={restaurant.timezone}
          openingHours={restaurantOpeningHours}
          borderRadius={72}
          py={2}
          px={8}
          _hover={{ color: color, bg: backgroundColor, opacity: '50%' }}
          isLoading={isLoadingRestaurantOpeningHours || isLoadingRestaurantStatus}
          onClick={navigateToMenu}
          borderColor="#E2E8F0"
          borderWidth={1}
          backgroundColor={backgroundColor}
          color={color}
        />
      </Flex>
    </Card>
  );
};
