import { BackboneCustomerOrder, initializeBackboneCustomerOrder } from './backboneCustomerOrderApiClient';
import { GatewayClickCollect, initializeGatewayClickCollect } from './gatewayClickCollectApiClient';

export interface TasterApis {
  gatewayClickCollect: GatewayClickCollect;
  backboneCustomerOrder: BackboneCustomerOrder;
}

export const initialize = (): TasterApis => {
  const gatewayClickCollect = initializeGatewayClickCollect();
  const backboneCustomerOrder = initializeBackboneCustomerOrder();

  return { backboneCustomerOrder, gatewayClickCollect };
};
