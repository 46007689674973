import { createContext, PropsWithChildren, useContext } from 'react';

import * as tsClients from './tsClients';

export const TasterApisContext = createContext<tsClients.TasterApis>({} as tsClients.TasterApis);

export const TasterApisProvider = ({ children }: PropsWithChildren) => {
  const tasterApis = tsClients.initialize();

  return <TasterApisContext.Provider value={tasterApis}>{children}</TasterApisContext.Provider>;
};

export const useTasterApis = () => useContext(TasterApisContext);
