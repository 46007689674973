export const getAllergensLink = (languageCode: string) => {
  const allergensLinks: Record<string, string> = {
    fr: 'https://help.taster.com/fr/articles/5494337-informations-sur-les-allergenes-et-regimes-alimentaires',
    be: 'https://help.taster.com/fr/articles/5494337-informations-sur-les-allergenes-et-regimes-alimentaires',
    gb: 'https://help.taster.com/en/articles/5494337-information-on-allergens-dietary-requirements',
    nl: 'https://help.taster.com/nl/articles/5494337-informatie-over-allergenen-en-dieetwensen',
  };

  return allergensLinks[languageCode];
};
