import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import QRCode from 'react-qr-code';

import QrCodeArrow from '@/assets/img/qr_code_arrow.svg';
import { getAllergensLink } from '@/helpers/restaurant.helpers';

export interface MenuAllergensModalInfoProps {
  languageCode: string;
  open: boolean;
  onClose: () => void;
}

export const MenuAllergensModalInfo = ({ languageCode, open, onClose }: MenuAllergensModalInfoProps) => {
  const allergensLink = getAllergensLink(languageCode);

  return (
    <Modal isOpen={open} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Information on allergens</Trans>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text>
            <Trans>Scan the QR code below for the full list of allergens and associated dietary restrictions.</Trans>
          </Text>

          <Flex justifyContent="center" py={12} position="relative">
            <QRCode value={allergensLink} size={200} />
            <Image
              src={QrCodeArrow}
              sx={{
                display: { base: 'none', sm: 'block' },
                position: 'absolute',
                transform: 'translateX(120%) translateY(-25%)',
              }}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
