import { Avatar, Stack, Text } from '@chakra-ui/react';

import TasterIcon from '@/assets/img/taster_circle_logo.svg';

interface UserInfoProps {
  kitchenLabel: string;
}

export const UserInfo = ({ kitchenLabel }: UserInfoProps) => {
  return (
    <Stack alignItems="center">
      <Avatar
        src={TasterIcon}
        sx={{
          background: 'white',
          border: `1px solid`,
          borderColor: 'gray.300',
          width: '96px',
          height: '96px',
        }}
      />
      <Text fontWeight={700} fontSize={24} color="gray.700">
        {kitchenLabel}
      </Text>
    </Stack>
  );
};
