import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ResponseError } from '@tasterkitchens/client-ts-gateway-click-collect-v1';
import camelize from 'camelize-ts';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { terminalsQueryKeys } from './terminals.helpers';
import { GetTerminalRequest, RegisteredTerminal } from './types';

export const useTerminalQueryOptions = {
  queryKey: (requestParams?: GetTerminalRequest) => [...terminalsQueryKeys.all, 'get', requestParams] as const,
};

export interface UseTerminalQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<RegisteredTerminal, ApiError, TData, ReturnType<typeof useTerminalQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
  requestParams?: GetTerminalRequest;
}

export const useTerminalQuery = <TData = RegisteredTerminal>({
  options,
  requestParams,
}: UseTerminalQueryProps<TData>) => {
  const { authenticationPassPhrase } = useOnSiteInfo();
  const {
    gatewayClickCollect: { terminalsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: async () => {
      if (!requestParams) {
        return Promise.reject('Invalid query params');
      }

      try {
        return await terminalsApi.getTerminal(requestParams, async ({ init }) => ({
          ...init,
          headers: {
            ...init.headers,
            Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`,
          },
        }));
      } catch (error) {
        if (error instanceof ResponseError) {
          const errorResponse = (await error.response.json()) as ApiError;
          throw new ApiError(camelize(errorResponse));
        }

        throw error;
      }
    },
    queryKey: useTerminalQueryOptions.queryKey(requestParams),
  });
};
