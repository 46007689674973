import { Heading, Image, Spinner, Stack, useBoolean } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useOrderQuery } from '@/api/gateway-click-collect/orders';
import paymentAcceptedImgSrc from '@/assets/img/payment_accepted.png';
import paymentRejectedImgSrc from '@/assets/img/payment_rejected.png';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { AppLayout } from '@/layouts/AppLayout';

const Success = () => {
  return (
    <Stack alignItems="center" textAlign="center">
      <Heading>
        <Image src={paymentAcceptedImgSrc} />
        <Trans>Payment accepted</Trans>
      </Heading>
      <p>
        <Trans>Redirecting you to your order</Trans>
      </p>
    </Stack>
  );
};

const Failure = () => {
  return (
    <Stack alignItems="center" textAlign="center">
      <Image w={216} h={216} margin="auto" src={paymentRejectedImgSrc} mt="30%" />
      <Heading>
        <Trans>Payment refused</Trans>
      </Heading>
      <p>
        <Trans>Redirecting you to your cart</Trans>
      </p>
    </Stack>
  );
};

const ConfirmPaymentPage = () => {
  const { orderUuid } = useParams() as { orderUuid: string };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get('redirect_status');
  const [isReadyToNavigate, setIsReadyToNavigate] = useBoolean(false);
  const { resetCart } = useCart();

  usePageViewTracker();

  const { data: order, isLoading: isOrderLoading } = useOrderQuery({ requestParams: { orderUuid } });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsReadyToNavigate.toggle();
    }, 3000);

    return () => clearTimeout(timeout);
  }, [setIsReadyToNavigate]);

  useEffect(() => {
    if (isReadyToNavigate && !isOrderLoading) {
      if (paymentStatus === 'succeeded') {
        if (order) {
          dataLayer.logPaymentSuccess({
            order,
            orderChannel: 'online',
          });
        }

        resetCart();
        navigate(`/order/${orderUuid}`);
      } else if (order) {
        navigate(`/menu/${order.restaurantPlatformId}`);
      } else {
        navigate('/');
      }
    }
  }, [isOrderLoading, isReadyToNavigate, navigate, order, orderUuid, paymentStatus, resetCart]);

  return (
    <AppLayout title={<Trans>Payment</Trans>} showLogo>
      <Stack alignItems="center">
        {paymentStatus !== null && paymentStatus !== 'succeeded' && <Failure />}
        {paymentStatus === 'succeeded' && <Success />}
        <Spinner marginTop={4} size="xl" />
      </Stack>
    </AppLayout>
  );
};

export default ConfirmPaymentPage;
