import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

export const useAuthToken = () => {
  const auth = useAuth();

  const getToken = useCallback(async () => {
    let token = auth.user?.access_token;

    if (auth.user?.expired) {
      const user = await auth.signinSilent();

      if (user) {
        token = user?.access_token;
      } else {
        await auth.signinRedirect({
          redirect_uri: `${window.origin}${window.location.pathname}`,
        });
      }
    }

    return token;
  }, [auth]);

  return { getToken };
};
