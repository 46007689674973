import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioProps,
  Spinner,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { useUserLocationsQuery } from '@/api/gateway-click-collect';
import { AsyncContent } from '@/components/AsyncContent';
import { useUserLocation } from '@/hooks';

import { PairDeviceLayout } from './PairDeviceLayout';

const KitchenRadioCard = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  return (
    <Box as="label">
      <input {...getInputProps()} />
      <Box
        {...getCheckboxProps()}
        cursor="pointer"
        borderWidth="2px"
        height="100%"
        borderRadius="md"
        background={props.isChecked ? '#F5F5F5' : '#FFFF'}
        width="100%"
        px={4}
        py={6}
        _checked={{
          borderColor: 'black.500',
        }}
      >
        <Stack direction="row" gap={2} flexWrap="nowrap" height="100%" alignItems="center">
          <Radio isChecked={props.isChecked} />
          {props.children}
        </Stack>
      </Box>
    </Box>
  );
};

export const InternalLocationsPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchValue, setSearchValue] = useState('');
  const { setUserLocation } = useUserLocation();

  const {
    data: locations = [],
    isLoading,
    isError,
  } = useUserLocationsQuery({
    options: {
      select: (response) => {
        return sortBy(response, 'kitchenLabel');
      },
    },
  });

  useEffect(() => {
    if (locations.length === 1) {
      setUserLocation(locations[0]);
      navigate('/onSite/pairTerminal');
    }
  }, [locations, navigate, setUserLocation]);

  const { getRadioProps, getRootProps, value: selectedLocationUuid } = useRadioGroup();

  const showSearchBar = locations.length >= 5;
  const searchedLocations = locations.filter(({ kitchenLabel }) =>
    kitchenLabel.toLowerCase().includes(searchValue.trim().toLowerCase())
  );

  const handleNextStepClick = () => {
    const selectedLocation = locations.find(({ locationUuid }) => locationUuid === selectedLocationUuid);

    if (selectedLocation) {
      setUserLocation(selectedLocation);
      navigate('/onSite/pairTerminal');
    }
  };

  const handleLogout = () =>
    auth.signoutRedirect({ post_logout_redirect_uri: `${window.origin}${window.location.pathname}` });
  return (
    <PairDeviceLayout onLogoutUser={handleLogout} headerTitle={t`Pair a card machine`}>
      <Stack gap={12} alignItems="center" justifyContent="start">
        <Heading as="h2" fontWeight={500} size="lg">
          <Trans>Select kitchen</Trans>
        </Heading>
        <AsyncContent
          isLoading={isLoading}
          data={locations}
          hasData={!!locations.length}
          noDataText={<Trans>You dont have access to any Taster kitchens</Trans>}
          LoadingContent={<Spinner size="xl" />}
          isError={isError}
          renderContent={() => {
            return (
              <Stack gap={6} width="100%" maxW={500} px={8}>
                {showSearchBar && (
                  <InputGroup>
                    <InputLeftElement>
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      sx={{ background: 'white' }}
                    />
                  </InputGroup>
                )}
                <Stack {...getRootProps()} as="ul" gap={2} overflow="auto">
                  {searchedLocations.map(({ kitchenLabel, locationUuid }) => {
                    return (
                      <li key={locationUuid}>
                        <KitchenRadioCard {...getRadioProps({ value: locationUuid })}>
                          <Text fontSize="md" fontWeight={500} wordBreak="break-all">
                            {kitchenLabel}
                          </Text>
                        </KitchenRadioCard>
                      </li>
                    );
                  })}
                </Stack>
              </Stack>
            );
          }}
        />
        <Button
          isDisabled={!selectedLocationUuid}
          width="100%"
          maxWidth={220}
          paddingY={8}
          fontSize={24}
          onClick={handleNextStepClick}
        >
          <Trans>Next</Trans>
        </Button>
      </Stack>
    </PairDeviceLayout>
  );
};
