import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderStatusEnum, useOrderQuery } from '@/api/gateway-click-collect/orders';
import { usePendingOrder } from '@/contexts/PendingOrderProvider';

const PENDING_ORDER_STATUSES = new Set<string>([
  OrderStatusEnum.Accepted,
  OrderStatusEnum.Created,
  OrderStatusEnum.CreationInProgress,
  OrderStatusEnum.Initiated,
  OrderStatusEnum.PaymentFailed,
  OrderStatusEnum.PaymentSucceeded,
  OrderStatusEnum.ReadyForDispatch,
]);

const PROCESSABLE_ORDER_STATUSES = new Set<OrderStatusEnum>([OrderStatusEnum.Initiated, OrderStatusEnum.PaymentFailed]);

export const PendingOrderButton = (props: ButtonProps) => {
  const navigate = useNavigate();
  const { pendingOrder, setPendingOrder } = usePendingOrder();

  const { data: order } = useOrderQuery({
    requestParams: pendingOrder
      ? {
          orderUuid: pendingOrder,
        }
      : undefined,
    options: { enabled: !!pendingOrder },
  });

  useEffect(() => {
    if (order && !PENDING_ORDER_STATUSES.has(order.status)) {
      setPendingOrder(null);
    }
  }, [order, setPendingOrder]);

  const handleRedirect = () => {
    if (order) {
      PROCESSABLE_ORDER_STATUSES.has(order.status)
        ? navigate(`/checkout/${order.uuid}`)
        : navigate(`/order/${order.uuid}`);
    }
  };

  if (order && PENDING_ORDER_STATUSES.has(order.status)) {
    return (
      <Button
        borderRadius="md"
        bottom={3}
        left="50%"
        maxWidth={640}
        onClick={handleRedirect}
        position="fixed"
        size="lg"
        transform="translateX(-50%)"
        width="calc(100% - 32px)"
        zIndex={1}
        {...props}
      >
        <Spinner mr={2} />
        <Trans>Order in progress</Trans>
      </Button>
    );
  }

  return null;
};
