import { ButtonProps } from '@chakra-ui/button/dist/button';
import { Button } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Restaurant } from '@tasterkitchens/client-ts-gateway-click-collect-v1';

import { canPreOrder, isRestaurantOpen } from '@/helpers/openingHours.helpers';

export type OrderButtonProps = Pick<Restaurant, 'openingHours' | 'timezone' | 'status'> & ButtonProps;

export const OrderButton = ({ status, openingHours, timezone, ...buttonProps }: OrderButtonProps) => {
  if (isRestaurantOpen(status)) {
    return (
      <Button {...buttonProps}>
        <Trans>Order</Trans>
      </Button>
    );
  }

  if (canPreOrder({ openingHours, status, timezone })) {
    return (
      <Button
        {...buttonProps}
        borderWidth={1}
        backgroundColor="#F8F7F5"
        borderColor={buttonProps.backgroundColor}
        color={buttonProps.backgroundColor}
      >
        <Trans>Preorder</Trans>
      </Button>
    );
  }

  return (
    <Button
      {...buttonProps}
      borderWidth={1}
      backgroundColor="#F8F7F5"
      borderColor={buttonProps.backgroundColor}
      color={buttonProps.backgroundColor}
    >
      <Trans>See menu</Trans>
    </Button>
  );
};
