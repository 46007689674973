import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@/api/gateway-click-collect/restaurants/types';
import { KioskMenuCard } from '@/components/Kiosk/KioskMenuCard';
import { MenuAllergensModalInfo } from '@/components/RestaurantInfo/MenuAllergensModalInfo';
import { useCart } from '@/contexts/cart';
import { useMenuDataContext } from '@/contexts/MenuDataProvider';
import dataLayer from '@/helpers/dataLayer.helpers';
import { getAllergensLink } from '@/helpers/restaurant.helpers';
import { usePollingTerminalStatus } from '@/hooks/usePollingTerminalStatus';
import { MenuItemGridLayout } from '@/layouts/MenuItemGridLayout';

import { formatCategories } from './MenuCategoriesPage.helpers';
import { MenuCategoriesPageFooter } from './MenuCategoriesPageFooter';
import { MenuCategoriesPageHeader } from './MenuCategoriesPageHeader';

export const MenuCategoriesPage = () => {
  const navigate = useNavigate();
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };

  const {
    conceptInformation,
    menu,
    outOfStocks,
    restaurant: { locationUuid, kitchenLabel, languageCode },
    terminal: { terminalInfo },
  } = useMenuDataContext();

  usePollingTerminalStatus({
    locationUuid,
    terminalUuid: terminalInfo.terminalUuid,
    maxTry: 5,
  });

  const categories = formatCategories(menu, outOfStocks);

  const { cart } = useCart();
  const itemsQuantity = cart.items.reduce((quantityAcc, item) => quantityAcc + item.quantity, 0);
  const hasItems = !!itemsQuantity;

  const navigateToCategoryMenuItems = (category: Category) => {
    dataLayer.logOrderingKioskCategoryClick({
      brandLabel: conceptInformation.conceptName,
      categoryName: category.label,
      kitchenLabel,
    });

    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    navigate(`/onSite/menu/${restaurantPlatformId}/categories/${category.uuid}`);
  };

  const allergensLink = getAllergensLink(languageCode);
  const {
    isOpen: isAllergensModalOpen,
    onOpen: onOpenAllergensModalOpen,
    onClose: onCloseAllergensModalOpen,
  } = useDisclosure();

  return (
    <>
      <Stack pb={12} minHeight={0} gap={3}>
        <MenuCategoriesPageHeader conceptInformation={conceptInformation} locationUuid={locationUuid} />
        <Stack px={4} gap={3} pb={125} justifyContent="center">
          {categories && (
            <>
              <MenuItemGridLayout sx={{ pb: 10 }}>
                {categories.map((category) => {
                  const { uuid, label, picture } = category;

                  return (
                    <KioskMenuCard
                      label={label}
                      key={uuid}
                      pictureUrl={picture}
                      onClick={() => navigateToCategoryMenuItems(category)}
                    />
                  );
                })}
              </MenuItemGridLayout>
              {allergensLink && (
                <Button
                  onClick={onOpenAllergensModalOpen}
                  variant="link"
                  sx={{ justifyContent: 'center', textDecoration: 'underline' }}
                >
                  <Trans>{`See ${conceptInformation.conceptName}'s allergens`}</Trans>
                </Button>
              )}
            </>
          )}
        </Stack>
        {hasItems && (
          <MenuCategoriesPageFooter
            itemsQuantity={itemsQuantity}
            locationUuid={locationUuid}
            onCartClick={() => {
              dataLayer.clickCart({ brandLabel: conceptInformation.conceptName, kitchenLabel, orderChannel: 'onsite' });
            }}
          />
        )}
      </Stack>
      <MenuAllergensModalInfo
        languageCode={languageCode}
        open={isAllergensModalOpen}
        onClose={onCloseAllergensModalOpen}
      />
    </>
  );
};
