import { SearchIcon } from '@chakra-ui/icons';
import { FormControl, FormLabel, Icon } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { chakraComponents, DropdownIndicatorProps, InputActionMeta, Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useId, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng, Suggestion } from 'use-places-autocomplete';

import type { Coords } from '@/api/gmap';

export type Geolocation = {
  address?: string;
  coords?: Coords;
};

type GeolocationAutocompleteProps = {
  defaultValue?: string;
  onSelect: (value: Geolocation) => void;
};

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <chakraComponents.DropdownIndicator {...props}>
    <Icon as={SearchIcon} />
  </chakraComponents.DropdownIndicator>
);

export const GeolocationAutocomplete = ({ defaultValue = '', onSelect = () => {} }: GeolocationAutocompleteProps) => {
  const {
    setValue,
    suggestions: { loading, data },
    value,
  } = usePlacesAutocomplete({
    defaultValue,
    requestOptions: {
      language: dayjs.locale(),
    },
    debounce: 600,
  });
  const [selectedOption, setSelectedOption] = useState<Suggestion>();
  const id = useId();
  const inputId = `${id}-input`;
  const labelId = `${id}-label`;

  const handleInputChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setValue(inputValue);
    }
  };

  const handleChange = (option: Suggestion) => {
    setSelectedOption(option);

    if (option) {
      const address = option.description as string;
      getGeocode({ address }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        onSelect({ address, coords: { latitude: lat, longitude: lng } });
      });
    }
  };

  return (
    <FormControl>
      <FormLabel id={labelId} htmlFor={inputId} fontSize="sm">
        <Trans>Find a location</Trans>
      </FormLabel>
      <Select
        aria-labelledby={labelId}
        closeMenuOnSelect
        components={{ DropdownIndicator }}
        filterOption={null}
        getOptionLabel={(option) => option.description}
        getOptionValue={(option) => option.place_id}
        id={inputId}
        inputValue={value}
        isLoading={loading}
        noOptionsMessage={() => null}
        onChange={handleChange}
        onInputChange={handleInputChange}
        openMenuOnClick={!!value}
        options={data}
        placeholder={<Trans>Enter location</Trans>}
        value={selectedOption}
      />
    </FormControl>
  );
};
