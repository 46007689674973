import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Heading,
  IconButton,
  Link,
  Stack,
  SystemStyleObject,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { FaCopy, FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';

import { useDeliveryPlatformsQuery } from '@/api/gateway-click-collect/locations';
import { ContactInfo, Restaurant } from '@/api/gateway-click-collect/restaurants';
import { DeliveryPlatformInfo } from '@/components/DeliveryPlatformInfo';
import { NF525Badge } from '@/components/NF525Badge';
import { OpeningHoursInfo } from '@/components/RestaurantInfo/OpeningHoursInfo';

const TASTER_CONTACT_EMAIL = 'contact@taster.com';

const LegalContactInfo = ({ contactEmail, contactPhone }: ContactInfo) => {
  const email = contactEmail ?? TASTER_CONTACT_EMAIL;

  return (
    <Stack gap={3}>
      {contactPhone && (
        <Flex gap={2} alignItems="center">
          <PhoneIcon /> <a href={`tel:${contactPhone}`}>{contactPhone}</a>
        </Flex>
      )}
      <Flex gap={2} alignItems="center">
        <EmailIcon />
        <a href={`mailto:${email}`}>{email}</a>
      </Flex>
    </Stack>
  );
};

export interface RestaurantInfoProps {
  menuDescription: string;
  restaurant: Restaurant;
  sx?: SystemStyleObject;
}

export const RestaurantInfo = ({ menuDescription, restaurant, sx }: RestaurantInfoProps) => {
  const { address, locationUuid, kitchenLabel, conceptUuid, restaurantLabel, contactInfo } = restaurant;
  const { isOpen: isDrawerOpen, onOpen: openDrawer, onClose: closeDrawer } = useDisclosure();

  const copyAddressToClipboard = () => {
    return navigator.clipboard.writeText(address);
  };

  const { data: deliveryPlatformsRestaurant, isSuccess } = useDeliveryPlatformsQuery({
    requestParams: {
      locationUuid,
    },
    options: {
      select: (deliveryPlatforms) => deliveryPlatforms.deliveryRestaurants[conceptUuid] ?? [],
    },
  });

  const responsiveDrawerProps = useBreakpointValue<Pick<DrawerProps, 'placement' | 'size'>>({
    base: { placement: 'bottom', size: 'xl' },
    md: { placement: 'right', size: 'md' },
  });

  return (
    <>
      <Button onClick={openDrawer} leftIcon={<FaInfoCircle />} sx={sx} variant="link">
        <Trans>More Info</Trans>
      </Button>
      <Drawer isOpen={isDrawerOpen} onClose={closeDrawer} {...responsiveDrawerProps}>
        <DrawerOverlay />
        <DrawerContent
          sx={{
            borderTopRadius: { base: '3xl', md: 0 },
            maxHeight: { base: '85vh', md: '100vh' },
          }}
        >
          <DrawerHeader>
            <Center>
              <Heading as="h2" size={'lg'}>
                <Trans>Informations</Trans>
              </Heading>
            </Center>
            <DrawerCloseButton top={4} />
          </DrawerHeader>
          <Divider mb={4} />
          <DrawerBody paddingBottom="150px">
            <Heading as="h3" size="md" marginBottom={2}>
              {restaurantLabel}
            </Heading>
            <Text color={'gray.600'} marginBottom={2}>
              {menuDescription}
            </Text>
            <Divider my={4} />
            <Flex gap={2} mb={2} alignItems="center">
              <FaMapMarkerAlt />
              <Link href={encodeURI(`https://www.google.com/maps/search/?api=1&query=${address}`)}>{address}</Link>
              <IconButton
                icon={<FaCopy />}
                marginLeft={'auto'}
                aria-label={t`copy restaurant address`}
                onClick={copyAddressToClipboard}
                variant={'ghost'}
              />
            </Flex>
            <LegalContactInfo {...contactInfo} />
            <Divider my={4} />
            <OpeningHoursInfo restaurant={restaurant} />
            <Divider my={4} />
            <div>
              {isSuccess && (
                <>
                  <Stack spacing={3}>
                    {deliveryPlatformsRestaurant?.map(({ platformUuid, platformLabel, platformLogo, platformUrl }) => {
                      return (
                        <DeliveryPlatformInfo
                          key={platformUuid}
                          platformLabel={platformLabel}
                          platformLogo={platformLogo}
                          platformUrl={platformUrl}
                          kitchenLabel={kitchenLabel}
                        />
                      );
                    })}
                  </Stack>
                  {!!deliveryPlatformsRestaurant?.length && <Divider my={4} />}
                </>
              )}
            </div>
            <NF525Badge />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
