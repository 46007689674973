import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';

import { Restaurant } from '@/api/gateway-click-collect/restaurants';
import { canPreOrder, getCurrentShift, getNextShift, isRestaurantOpen } from '@/helpers/openingHours.helpers';

const DotStatus = ({ color }: { color: string }) => {
  return (
    <Box
      as="span"
      backgroundColor={color}
      borderRadius="full"
      display="inline-block"
      height={2}
      marginRight={1}
      width={2}
    />
  );
};

export type OpeningHoursLabelProps = Pick<Restaurant, 'openingHours' | 'timezone' | 'status'> & { isLoading: boolean };

export const OpeningHoursLabel = ({ openingHours, timezone, status, isLoading }: OpeningHoursLabelProps) => {
  if (isLoading) {
    return <Skeleton height={5} width={40} />;
  }

  if (isRestaurantOpen(status)) {
    const currentShift = getCurrentShift({ openingHours, timezone });
    const currentShiftTime = currentShift?.endTime ? dayjs(currentShift.endTime, 'HH:mm:ss').format('LT') : null;
    return (
      <Flex fontSize="sm" fontWeight={500} gap={1} alignItems="center">
        <DotStatus color={'green.500'} />
        <Trans>Open until {currentShiftTime}</Trans>
      </Flex>
    );
  }

  if (canPreOrder({ openingHours, status, timezone })) {
    const nextShift = getNextShift({ openingHours, timezone });
    const nextOpenTime = nextShift?.startTime ? dayjs(nextShift.startTime, 'HH:mm:ss').format('LT') : null;

    return (
      <Flex fontSize="sm" fontWeight={500} gap={1} alignItems="center">
        <DotStatus color={'gray.500'} />
        <Trans>Opens at {nextOpenTime}</Trans>
      </Flex>
    );
  }

  return (
    <Flex fontSize="sm" fontWeight={500} gap={1} alignItems="center">
      <DotStatus color={'gray.500'} />
      <Trans>Closed</Trans>
    </Flex>
  );
};
