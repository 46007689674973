import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import camelize from 'camelize-ts';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { locationsKeys } from './locations.helpers';
import { GetDeliveryPlatformsRequest, LocationDeliveryPlatforms } from './types';

export const useDeliveryPlatformsQueryOptions = {
  queryKey: (requestParams: GetDeliveryPlatformsRequest) => [...locationsKeys.all, 'deliveryPlatforms', requestParams],
};

export interface UseDeliveryPlatformsQueryProps<TData = LocationDeliveryPlatforms> {
  options?: Omit<
    UseQueryOptions<
      LocationDeliveryPlatforms,
      ApiError,
      TData,
      ReturnType<typeof useDeliveryPlatformsQueryOptions.queryKey>
    >,
    'queryFn' | 'queryKey'
  >;
  requestParams: GetDeliveryPlatformsRequest;
}

export const useDeliveryPlatformsQuery = <TData = LocationDeliveryPlatforms>({
  options,
  requestParams,
}: UseDeliveryPlatformsQueryProps<TData>) => {
  const {
    gatewayClickCollect: { locationsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryKey: useDeliveryPlatformsQueryOptions.queryKey(requestParams),
    queryFn: async () => {
      const locationDeliveryPlatforms = await locationsApi.getDeliveryPlatforms(requestParams);
      const deliveryRestaurants = locationDeliveryPlatforms.deliveryRestaurants || {};

      const formattedDeliveryRestaurants = Object.keys(deliveryRestaurants).reduce(
        (deliveryRestaurants, uuid) => ({
          ...deliveryRestaurants,
          [uuid]: camelize(locationDeliveryPlatforms.deliveryRestaurants[uuid]),
        }),
        {}
      );

      return { ...locationDeliveryPlatforms, deliveryRestaurants: formattedDeliveryRestaurants };
    },
  });
};
