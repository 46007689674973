import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { GetRestaurantOpeningHoursRequest, OpeningHours } from './types';

export const useRestaurantOpeningHoursQueryOptions = {
  queryKey: (requestParams: GetRestaurantOpeningHoursRequest) =>
    [...restaurantsKeys.all, 'getRestaurantOpeningHours', requestParams] as const,
};

export interface RestaurantOpeningHoursQueryProps<TData> {
  requestParams: GetRestaurantOpeningHoursRequest;
  options?: Omit<
    UseQueryOptions<OpeningHours, ApiError, TData, ReturnType<typeof useRestaurantOpeningHoursQueryOptions.queryKey>>,
    'queryFn' | 'queryKey'
  >;
}

export const useRestaurantOpeningHoursQuery = <TData = OpeningHours>({
  requestParams,
  options,
}: RestaurantOpeningHoursQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    queryKey: useRestaurantOpeningHoursQueryOptions.queryKey(requestParams),
    queryFn: () => restaurantsApi.getRestaurantOpeningHours(requestParams),
    ...options,
  });
};
