import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { AiOutlineWarning } from 'react-icons/ai';

import Card from '@/components/Card';
import { PairTerminalButton } from '@/components/PairingTerminal/PairTerminalButton';

import { RefreshTerminalStatusContent } from './RefreshTerminalStatusContent';

export interface TerminalStatusErrorCardProps {
  lastUpdateAt: number;
  isRefreshing?: boolean;
  onRefreshClick: () => void;
  errorStatus?: number;
}

export const TerminalStatusErrorCard = ({
  lastUpdateAt,
  isRefreshing = false,
  onRefreshClick,
  errorStatus,
}: TerminalStatusErrorCardProps) => {
  return (
    <Card
      sx={{
        backgroundColor: 'red.error.50',
        border: '1px solid',
        borderColor: 'red.error.600',
        p: 4,
      }}
    >
      <Stack gap={2}>
        <HStack gap={2} justifyContent="space-between">
          <Text fontSize={20} fontWeight={600}>
            <Trans>Status</Trans>
          </Text>
          <RefreshTerminalStatusContent
            onRefreshClick={onRefreshClick}
            isRefreshing={isRefreshing}
            lastUpdatedAt={lastUpdateAt}
          />
        </HStack>
        <HStack flexWrap="wrap" justifyContent="space-between" alignItems="start">
          <HStack gap={2} alignItems="start">
            <Icon as={AiOutlineWarning} width={6} height={6} color="red.error.600" />
            <Stack gap={1}>
              <Text color="red.error.600" fontWeight={600} fontSize={20}>
                {errorStatus === 410 ? <Trans>Unavailable</Trans> : <Trans>Not paired</Trans>}
              </Text>
              <Text color="red.error.600">
                <Trans>To continue, pair the card machine.</Trans>
              </Text>
            </Stack>
          </HStack>
          <PairTerminalButton variant="contained" sx={{ background: 'black', color: 'white' }} />
        </HStack>
      </Stack>
    </Card>
  );
};
