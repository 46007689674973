import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { configurations } from './configurations.helpers';
import { ConceptsConfiguration, GetConceptsConfigurationRequest } from './types';

export const useConceptsConfigurationQueryOptions = {
  queryKey: (requestParams?: GetConceptsConfigurationRequest) =>
    [...configurations.all, 'getConceptsConfiguration', requestParams] as const,
};

export interface ConceptsConfigurationQueryProps<TData> {
  requestParams?: GetConceptsConfigurationRequest;
  options?: Omit<
    UseQueryOptions<
      ConceptsConfiguration,
      Error,
      TData,
      ReturnType<typeof useConceptsConfigurationQueryOptions.queryKey>
    >,
    'queryFn' | 'queryKey'
  >;
}

export const useConceptsConfigurationQuery = <TData = ConceptsConfiguration>({
  requestParams,
  options = {},
}: ConceptsConfigurationQueryProps<TData>) => {
  const { authenticationPassPhrase, hasPairedTerminal } = useOnSiteInfo();
  const {
    gatewayClickCollect: { configurationsApi },
  } = useTasterApis();

  return useQuery({
    queryKey: useConceptsConfigurationQueryOptions.queryKey(requestParams),
    queryFn: () => {
      return configurationsApi.getConceptsConfiguration(requestParams, async ({ init }) => {
        return hasPairedTerminal
          ? {
              ...init,
              headers: {
                ...init.headers,
                Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`,
              },
            }
          : {};
      });
    },
    ...options,
  });
};
