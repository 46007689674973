import { Card, CardBody, Flex } from '@chakra-ui/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';

import { Restaurant, useRestaurantQueryOptions } from '@/api/gateway-click-collect/restaurants';
import { Coords } from '@/api/gmap';
import { RestaurantCard } from '@/components/RestaurantCard';
import { RestaurantWithConcept } from '@/types';

import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';

type KitchenGeolocationCardProps = {
  coords: Coords;
  restaurantList: RestaurantWithConcept[];
};

export const KitchenGeolocationCard = ({ coords, restaurantList }: KitchenGeolocationCardProps) => {
  const [wrapper] = useAutoAnimate();

  const { address, distance, kitchenLabel } = restaurantList[0];

  const sortedRestaurantInfos = sortBy(restaurantList, 'label');

  const kitchenConcepts = restaurantList.map(({ concept }) => concept);

  const googleMapUrl = useMemo(() => {
    const { latitude, longitude } = coords;
    return encodeURI(`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${address}`);
  }, [address, coords]);

  const queryClient = useQueryClient();

  const seedRestaurantQueryCache = (restaurantWithConcept: RestaurantWithConcept) => {
    const { concept: _, ...restaurant } = restaurantWithConcept;

    return queryClient.setQueryData<Restaurant>(
      useRestaurantQueryOptions.queryKey({
        restaurantPlatformId: restaurant.restaurantPlatformId,
      }),
      () => restaurant
    );
  };

  return (
    <Card boxShadow="unset" borderRadius={8}>
      <CardHeader address={address} distance={distance} addressUrl={googleMapUrl} kitchenLabel={kitchenLabel} />
      <CardBody paddingBlock={0}>
        <Flex ref={wrapper} direction="row" flexWrap="nowrap" overflow="auto" gap={4}>
          {sortedRestaurantInfos.map((restaurant) => (
            <RestaurantCard
              compact
              onRestaurantClick={seedRestaurantQueryCache}
              flexBasis={300}
              flexGrow={1}
              flexShrink={0}
              key={restaurant.restaurantPlatformId}
              restaurantWithConcept={restaurant}
            />
          ))}
        </Flex>
      </CardBody>
      <CardFooter concepts={kitchenConcepts} />
    </Card>
  );
};
